
import { computed, defineComponent, onMounted, ref } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import CourseForm from "@/components/CourseForm.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { lessonColumns, cancellationOfferColumns } from "@/columns";
import ApiGrid from "@/components/ApiGrid.vue";
import { routeNames } from "@/router";
import { CourseModel, LessonGridModel } from "@/models";
import { restClient } from "@/store/actions";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "course-form": CourseForm,
        "api-grid": ApiGrid,
        "loading-indicator": LoadingIndicator
    },
    props: {
        courseId: { type: Number, required: true }
    },
    setup(props) {
        const gridId = computed(() => `lesson-grid-${props.courseId}`);
        const gridCancellationOfferId = computed(
            () => `offer-grid-${props.courseId}`
        );
        const model = ref<CourseModel>({});
        const requestBody = ref<LessonGridModel>({});

        onMounted(async () => {
            if (props.courseId === 0) {
                model.value = {
                    id: 0,
                    name: null,
                    sortOrder: null,
                    summary: null,
                    headerImageUrl: " ",
                    logoImageUrl: " ",
                    courseColorHex: "#000000",
                    purchaseUrl: null,
                    bookClubCode: null,
                    agreementItemNumber: null,
                    subscriptionPublicationCode: null,
                    availableForPurchase: true
                };
            } else {
                const response = await restClient.getJson<CourseModel>(
                    `/api/admin/Course/${props.courseId}`
                );

                if (response.data) {
                    model.value = response.data;
                    requestBody.value = {
                        courseId: model.value.id
                    };
                }
            }
        });

        return {
            lessonColumns,
            cancellationOfferColumns,
            routeNames,
            model,
            requestBody,
            gridId,
            gridCancellationOfferId
        };
    }
});
