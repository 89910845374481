
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref
} from "vue";
import { Form, FormActions } from "vee-validate";
import { useRouter } from "vue-router";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import {
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadVue3
} from "@progress/kendo-vue-upload";
import {
    createImageFormData,
    formatDate,
    promptDelete,
    jpgUploadFileRestrictions,
    pngUploadFileRestrictions,
    createUniqueUrl,
    COURSE_LOGO_IMAGE_TOOLTIP_MESSAGE,
    COURSE_HEADER_IMAGE_TOOLTIP_MESSAGE
} from "@/helpers";
import { CourseModel } from "@/models";
import { routePaths } from "@/router";
import { AppGetterTypes, useStore } from "@/store";
import { restClient } from "@/store/actions";

import UploadPreview from "./UploadPreview.vue";
import InformationTooltipVue from "./InformationTooltip.vue";

export default defineComponent({
    props: {
        course: {
            type: Object as PropType<CourseModel>,
            required: true
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": UploadVue3,
        "upload-preview": UploadPreview,
        "information-tooltip": InformationTooltipVue
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const model: CourseModel = reactive(props.course);
        const rules = computed(
            () => store.getters[AppGetterTypes.validationRules]?.courseModel
        );

        const isNew = model.id === 0;
        const inserted = computed(() =>
            model.insertedOn ? formatDate(model.insertedOn) : "-"
        );
        const updated = computed(() =>
            model.updatedOn ? formatDate(model.updatedOn) : "-"
        );

        // Course Image upload
        const headerImageFile = ref<UploadFileInfo>();
        const headerPreviewSource = ref<string>();
        const headerImageHasChanged = ref(false);
        // Logo Image upload
        const logoImageFile = ref<UploadFileInfo>();
        const logoPreviewSource = ref<string>();
        const logoImageHasChanged = ref(false);

        onMounted(() => {
            // If editing, set the preview course image with a timestamp to cache bust
            if (!isNew && model?.headerImageUrl) {
                headerPreviewSource.value = createUniqueUrl(
                    model.headerImageUrl
                );
            }

            // If editing, set the preview logo image with a timestamp to cache bust
            if (!isNew && model?.logoImageUrl) {
                logoPreviewSource.value = createUniqueUrl(model.logoImageUrl);
            }
        });

        // Submit Form
        const onSubmit = async (
            values: CourseModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            // Trigger validation for new course on submit if no course image is selected
            if (isNew && !headerImageFile.value) {
                headerImageHasChanged.value = true;
            }

            // If the course image has changed, ensure it is valid and present
            if (
                (!headerImageFile.value && headerImageHasChanged.value) ||
                headerImageFile.value?.validationErrors
            ) {
                return;
            }

            // Trigger validation for new course on submit if no logo image is selected
            if (isNew && !logoImageFile.value) {
                logoImageHasChanged.value = true;
            }

            // If the logo image has changed, ensure it is valid and present
            if (
                (!logoImageFile.value && logoImageHasChanged.value) ||
                logoImageFile.value?.validationErrors
            ) {
                return;
            }

            // Set sort order to null if it has been removed
            if (!model.sortOrder) {
                model.sortOrder = null;
            }

            // Save course data
            const response = isNew
                ? await restClient.postJson<CourseModel>(
                      "/api/admin/Course",
                      model
                  )
                : await restClient.putJson<CourseModel>(
                      "/api/admin/Course",
                      model
                  );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // If the image course has changed and there are no errors, upload the new one
            if (headerImageHasChanged.value && headerImageFile.value) {
                const formData = createImageFormData(headerImageFile.value);

                // Save image
                const imageUploadResponse = await restClient.postJson(
                    `/api/admin/CourseImage/header?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(imageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }

            // If the logo image has changed and there are no errors, upload the new one
            if (logoImageHasChanged.value && logoImageFile.value) {
                const formData = createImageFormData(logoImageFile.value);

                // Save image
                const imageUploadResponse = await restClient.postJson(
                    `/api/admin/CourseImage/logo?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(imageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }
            router.push(routePaths.courseManagementPath);
        };

        // Prompt user to delete course
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete the course?"
                )
            ) {
                const response = await restClient.delete(
                    `/api/admin/Course/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                router.push(routePaths.courseManagementPath);
            }
        };

        // Add image to upload component and build preview
        const addCourseImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            headerImageFile.value = newImage;
            headerImageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        headerPreviewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new image from upload component
        const removeCourseImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this course image?” "
                )
            ) {
                headerImageFile.value = event.newState[0];
                headerPreviewSource.value = undefined;
            }
        };
        // Add image to upload component and build preview
        const addLogoImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            logoImageFile.value = newImage;
            logoImageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        logoPreviewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new image from upload component
        const removeLogoImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this logo image?” "
                )
            ) {
                logoImageFile.value = event.newState[0];
                logoPreviewSource.value = undefined;
            }
        };

        return {
            onSubmit,
            handleDelete,
            model,
            rules,
            routePaths,
            inserted,
            updated,
            isNew,
            addCourseImage,
            removeCourseImage,
            addLogoImage,
            removeLogoImage,
            headerImageFile,
            headerPreviewSource,
            headerImageHasChanged,
            logoImageFile,
            logoPreviewSource,
            logoImageHasChanged,
            jpgUploadFileRestrictions,
            pngUploadFileRestrictions,
            COURSE_LOGO_IMAGE_TOOLTIP_MESSAGE,
            COURSE_HEADER_IMAGE_TOOLTIP_MESSAGE
        };
    }
});
